import React, { useContext, useEffect, useState } from 'react';

import LeftFast from '../../images/LeftFast.svg';
import RightFast from '../../images/RightFast.svg';
import PlayButton from '../../images/Vector.svg';
import Volume from '../../images/Volume.svg';
import Close from '../../images/Close.svg';
import MusicSlider from './MusicSlider';
import VolumeSlider from './VolumeSlider';
import { AppContext } from '../../apollo/wrap-root-element';

const AudioPlayer = () => {
  const { selectedPodCast, setSelectedPodcast, isAudioPause, SetIsAudioPause } = useContext(AppContext);
  const [audio, setAudio] = useState(null);

  const [progressbarWidth, setProgressBarWidth] = useState(0);
  const [totalDuration, setTotalDurations] = useState('00:00');
  const [currentTime, setCurrentTime] = useState('00:00');

  const onCrossClicked = () => {
    setSelectedPodcast(null);
  };

  const fetchAudioSrcAndSetAudio = () => {
    const podcastShareId = selectedPodCast.data.podcastId[0].text;
    const audio = document.getElementById('audio');
    fetch(`${process.env.GATSBY_LINEAGE_BACKEND_URL}api/podcasts/${podcastShareId}`)
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        const { payload } = JSON.parse(data);
        audio.src = payload?.audioUrl;
        setAudio(audio);
      })
      .catch(function (err) {
        console.warn('Something went wrong.', err);
      });
  };

  useEffect(() => {
    setAudio(null);
    fetchAudioSrcAndSetAudio();
  }, [selectedPodCast]);

  useEffect(() => {
    if (!isAudioPause) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [isAudioPause]);

  useEffect(() => {
    if (selectedPodCast && audio) {
      SetIsAudioPause(false);
      audio.volume = 0.5;
      audio.currentTime = 0;
      audio?.play();
      DurTime();
    }
  }, [selectedPodCast, audio]);

  const updateAudioCurrentTime = (time) => {
    const currentTime = audio.currentTime;
    audio.currentTime = currentTime + time;
  };

  const onTimeUpdate = (e) => {
    if (audio) {
      const currentTime = audio.currentTime;
      const duration = audio.duration;
      const progressPercent = (currentTime / duration) * 100;
      DurTime();
      setProgressBarWidth(progressPercent);
    }
  };

  const setProgress = (e) => {
    const clickX = e.target.value;
    const duration = audio.duration;
    const currentTime = (duration / 100) * clickX;
    audio.currentTime = currentTime;
  };

  const toggleAudioPausePlay = () => {
    SetIsAudioPause(!isAudioPause);
  };

  const onVolumeChange = (e) => {
    audio.volume = e.target.value / 100;
  };

  const DurTime = () => {
    const currentTime = audio.currentTime;
    const duration = audio.duration;
    var sec;
    var sec_d;

    let min = currentTime == null ? 0 : Math.floor(currentTime / 60);
    min = min < 10 ? '0' + min : min;

    function get_sec(x) {
      if (Math.floor(x) >= 60) {
        for (var i = 1; i <= 60; i++) {
          if (Math.floor(x) >= 60 * i && Math.floor(x) < 60 * (i + 1)) {
            sec = Math.floor(x) - 60 * i;
            sec = sec < 10 ? '0' + sec : sec;
          }
        }
      } else {
        sec = Math.floor(x);
        sec = sec < 10 ? '0' + sec : sec;
      }
    }

    get_sec(currentTime, sec);

    const currentTimeRunning = min + ':' + sec;
    setCurrentTime(currentTimeRunning);

    let min_d = isNaN(duration) === true ? '0' : Math.floor(duration / 60);
    min_d = min_d < 10 ? '0' + min_d : min_d;

    function get_sec_d(x) {
      if (Math.floor(x) >= 60) {
        for (var i = 1; i <= 60; i++) {
          if (Math.floor(x) >= 60 * i && Math.floor(x) < 60 * (i + 1)) {
            sec_d = Math.floor(x) - 60 * i;
            sec_d = sec_d < 10 ? '0' + sec_d : sec_d;
          }
        }
      } else {
        sec_d = isNaN(duration) === true ? '0' : Math.floor(x);
        sec_d = sec_d < 10 ? '0' + sec_d : sec_d;
      }
    }
    get_sec_d(duration);
    const durations = min_d + ':' + sec_d;

    setTotalDurations(durations);
  };

  return (
    <>
      <audio id="audio" controls style={{ display: 'none' }} onTimeUpdate={onTimeUpdate}>
        Your browser does not support the
        <code>audio</code> element.
      </audio>
      <div className="audio-player">
        <div className="player">
          <div className="music-slider">
            <MusicSlider onChange={setProgress} value={progressbarWidth} />
          </div>
          <span className="duration-text">{currentTime}</span>
          <span className="duration-text">{totalDuration}</span>
        </div>
        <div className="audio-details-container">
          <div className="audio-details">
            <img
              src={selectedPodCast?.data?.miniPlayerThumbNail?.url}
              alt="podcast"
              style={{ borderRadius: '5px', width: '50px', height: '50px' }}
            />
            <div
              style={{
                paddingLeft: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <p title={selectedPodCast?.data?.podCastTitle[0].text} className="title">
                {selectedPodCast?.data?.podCastTitle[0].text}
              </p>
              <p className="subtitle">{selectedPodCast?.data?.season.uid.replace(/-/g, ' ')}</p>
            </div>
          </div>
          <div className="audio-controls">
            <div>
              <button onClick={() => updateAudioCurrentTime(-15)}>
                <img className="left-speed" src={LeftFast} alt="left" />
              </button>
              <button onClick={toggleAudioPausePlay}>
                {isAudioPause ? (
                  <div className="play-icon">
                    <img src={PlayButton} alt="vol" />
                  </div>
                ) : (
                  <div className="pause-icon"></div>
                )}
              </button>
              <button onClick={() => updateAudioCurrentTime(15)}>
                <img className="right-speed" src={RightFast} alt="right" />
              </button>
            </div>

            <div className="volume-cross-container">
              <img src={Volume} alt="left" className="volume-icon" />
              <div className="volume-slider">
                <VolumeSlider onChange={onVolumeChange} />
              </div>
              <div className="cross-mark">
                <button
                  style={{ backgroundColor: '#F1F1F1', padding: '4px', marginTop: 'auto', borderRadius: '4px' }}
                  title="exit"
                  onClick={onCrossClicked}
                >
                  <img src={Close} alt="right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioPlayer;
