// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-modules-books-page-book-chapter-page-template-js": () => import("./../../../src/modules/books-page/bookChapterPageTemplate.js" /* webpackChunkName: "component---src-modules-books-page-book-chapter-page-template-js" */),
  "component---src-modules-books-page-book-page-template-js": () => import("./../../../src/modules/books-page/bookPageTemplate.js" /* webpackChunkName: "component---src-modules-books-page-book-page-template-js" */),
  "component---src-modules-donate-page-donation-project-detail-page-js": () => import("./../../../src/modules/donate-page/DonationProjectDetailPage.js" /* webpackChunkName: "component---src-modules-donate-page-donation-project-detail-page-js" */),
  "component---src-modules-donate-page-language-donation-detail-page-js": () => import("./../../../src/modules/donate-page/LanguageDonationDetailPage.js" /* webpackChunkName: "component---src-modules-donate-page-language-donation-detail-page-js" */),
  "component---src-modules-language-page-index-js": () => import("./../../../src/modules/language-page/index.js" /* webpackChunkName: "component---src-modules-language-page-index-js" */),
  "component---src-modules-podcast-pod-cast-item-page-component-js": () => import("./../../../src/modules/podcast/PodCastItemPageComponent.js" /* webpackChunkName: "component---src-modules-podcast-pod-cast-item-page-component-js" */),
  "component---src-modules-read-page-read-category-js": () => import("./../../../src/modules/read-page/read-category.js" /* webpackChunkName: "component---src-modules-read-page-read-category-js" */),
  "component---src-modules-read-page-read-single-js": () => import("./../../../src/modules/read-page/read-single.js" /* webpackChunkName: "component---src-modules-read-page-read-single-js" */),
  "component---src-modules-shop-page-product-category-js": () => import("./../../../src/modules/shop-page/product-category.js" /* webpackChunkName: "component---src-modules-shop-page-product-category-js" */),
  "component---src-modules-shop-page-product-js": () => import("./../../../src/modules/shop-page/product.js" /* webpackChunkName: "component---src-modules-shop-page-product-js" */),
  "component---src-modules-timeline-components-event-event-single-js": () => import("./../../../src/modules/timeline/components/Event/event-single.js" /* webpackChunkName: "component---src-modules-timeline-components-event-event-single-js" */),
  "component---src-modules-timeline-components-people-people-jsx": () => import("./../../../src/modules/timeline/components/People/People.jsx" /* webpackChunkName: "component---src-modules-timeline-components-people-people-jsx" */),
  "component---src-modules-timeline-time-line-event-and-people-js": () => import("./../../../src/modules/timeline/TimeLineEventAndPeople.js" /* webpackChunkName: "component---src-modules-timeline-time-line-event-and-people-js" */),
  "component---src-modules-tour-tour-booking-js": () => import("./../../../src/modules/tour/tour-booking.js" /* webpackChunkName: "component---src-modules-tour-tour-booking-js" */),
  "component---src-modules-tour-tour-details-js": () => import("./../../../src/modules/tour/tour-details.js" /* webpackChunkName: "component---src-modules-tour-tour-details-js" */),
  "component---src-modules-watch-page-watch-category-page-component-js": () => import("./../../../src/modules/watch-page/WatchCategoryPageComponent.js" /* webpackChunkName: "component---src-modules-watch-page-watch-category-page-component-js" */),
  "component---src-modules-watch-page-watch-item-page-component-js": () => import("./../../../src/modules/watch-page/WatchItemPageComponent.js" /* webpackChunkName: "component---src-modules-watch-page-watch-item-page-component-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-coming-soon-the-great-controversy-js": () => import("./../../../src/pages/books-coming-soon/the-great-controversy.js" /* webpackChunkName: "component---src-pages-books-coming-soon-the-great-controversy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-donate-transactions-[id]-js": () => import("./../../../src/pages/donate-transactions/[id].js" /* webpackChunkName: "component---src-pages-donate-transactions-[id]-js" */),
  "component---src-pages-donation-history-js": () => import("./../../../src/pages/donation-history.js" /* webpackChunkName: "component---src-pages-donation-history-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-gcbooks-js": () => import("./../../../src/pages/gcbooks.js" /* webpackChunkName: "component---src-pages-gcbooks-js" */),
  "component---src-pages-give-js": () => import("./../../../src/pages/give.js" /* webpackChunkName: "component---src-pages-give-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-language-donations-page-js": () => import("./../../../src/pages/language-donations-page.js" /* webpackChunkName: "component---src-pages-language-donations-page-js" */),
  "component---src-pages-language-js": () => import("./../../../src/pages/language.js" /* webpackChunkName: "component---src-pages-language-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-new-give-js": () => import("./../../../src/pages/new-give.js" /* webpackChunkName: "component---src-pages-new-give-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-places-js": () => import("./../../../src/pages/places.js" /* webpackChunkName: "component---src-pages-places-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-presskit-js": () => import("./../../../src/pages/presskit.js" /* webpackChunkName: "component---src-pages-presskit-js" */),
  "component---src-pages-read-js": () => import("./../../../src/pages/read.js" /* webpackChunkName: "component---src-pages-read-js" */),
  "component---src-pages-recurring-donations-js": () => import("./../../../src/pages/recurring-donations.js" /* webpackChunkName: "component---src-pages-recurring-donations-js" */),
  "component---src-pages-reset-success-js": () => import("./../../../src/pages/reset-success.js" /* webpackChunkName: "component---src-pages-reset-success-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-success-donation-js": () => import("./../../../src/pages/success-donation.js" /* webpackChunkName: "component---src-pages-success-donation-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-testimonial-js": () => import("./../../../src/pages/testimonial.js" /* webpackChunkName: "component---src-pages-testimonial-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-timeline-details-js": () => import("./../../../src/pages/timeline-details.js" /* webpackChunkName: "component---src-pages-timeline-details-js" */),
  "component---src-pages-timeline-js": () => import("./../../../src/pages/timeline.js" /* webpackChunkName: "component---src-pages-timeline-js" */),
  "component---src-pages-tour-participation-interest-js": () => import("./../../../src/pages/tour-participation-interest.js" /* webpackChunkName: "component---src-pages-tour-participation-interest-js" */),
  "component---src-pages-tours-booking-confirmed-js": () => import("./../../../src/pages/tours/booking-confirmed.js" /* webpackChunkName: "component---src-pages-tours-booking-confirmed-js" */),
  "component---src-pages-tours-cancellations-js": () => import("./../../../src/pages/tours/cancellations.js" /* webpackChunkName: "component---src-pages-tours-cancellations-js" */),
  "component---src-pages-tours-index-js": () => import("./../../../src/pages/tours/index.js" /* webpackChunkName: "component---src-pages-tours-index-js" */),
  "component---src-pages-tours-payments-js": () => import("./../../../src/pages/tours/payments.js" /* webpackChunkName: "component---src-pages-tours-payments-js" */),
  "component---src-pages-watch-js": () => import("./../../../src/pages/watch.js" /* webpackChunkName: "component---src-pages-watch-js" */)
}

