import { useStaticQuery, graphql } from 'gatsby';

export const useCurrencyRate = () => {
  const { rate } = useStaticQuery(graphql`
    query q {
      rate: allOpenExchangeRates(filter: { currency: { in: ["EUR", "AUD", "GBP", "NZD", "CAD", "USD"] } }) {
        nodes {
          currency
          rate
        }
      }
    }
  `);

  return rate?.nodes || null;
};
