import { useState } from 'react';

export const useFetch = (method = 'GET') => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const mutate = (url, { body, onSuccess, onError }) => {
    setLoading(true);
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (method !== 'GET') {
      options['body'] = JSON.stringify(body);
    }
    fetch(process.env.GATSBY_LINEAGE_BACKEND_URL + url, options)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        onSuccess && onSuccess(res);
        setData(res);
      })
      .catch((error) => {
        setError(error);
        onError && onError(error);
      })
      .finally(() => setLoading(false));
  };

  return [mutate, data, error, loading];
};
