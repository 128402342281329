import * as React from 'react';
import { styled, alpha, Box } from '@mui/system';
import SliderUnstyled from '@mui/core/SliderUnstyled';

const StyledSlider = styled(SliderUnstyled)(
  ({ theme }) => `
  height: 8px;
  width: 100%;
  margin-left:4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }

  & .MuiSlider-rail {
    display: block;
    position: absolute;
    width: 100%;
    height: 7px;
    border-radius: 26px;
    background-color: #E8E8E8;

  }

  & .MuiSlider-track {
    display: block;
    position: absolute;
    height: 7px;
    border-radius:  26px;
    background-color: #FECB04;
  }

  & .MuiSlider-thumb {
    display:none;
    position: absolute;
    width: 14px;
    height: 14px;
    margin-left: -6px;
    margin-top: -5px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    border: 2px solid currentColor;
    background-color: #fff;

    :hover,
    &.Mui-focusVisible {
      box-shadow: 0 0 0 0.25rem ${alpha(theme.palette.mode === 'light' ? '#1976d2' : '#90caf9', 0.15)};
    }

    &.Mui-active {
      box-shadow: 0 0 0 0.25rem ${alpha(theme.palette.mode === 'light' ? '#1976d2' : '#90caf9', 0.3)};
    }
  }
`
);

export default function VolumeSlider(props) {
  return (
    <Box sx={{ width: 112 }}>
      <StyledSlider defaultValue={50} {...props} />
    </Box>
  );
}
