import { useState } from 'react';

export const useShopifyAPI = (method = 'POST') => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const mutate = (query, { onSuccess, onError }) => {
    setLoading(true);
    fetch(process.env.GATSBY_SHOPIFY_STORE_FRONT_API, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/graphql',
        'X-Shopify-Storefront-Access-Token': `${process.env.GATSBY_SHOPIFY_STORE_FRONT_ACCESS_TOKEN}`,
      },
      body: query,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        onSuccess && onSuccess(res);
        setData(res);
      })
      .catch((error) => {
        setError(error);
        onError && onError(error);
      })
      .finally(() => setLoading(false));
  };

  return [mutate, data, error, loading];
};
