import { isEmpty } from 'lodash';
import Client from 'shopify-buy';
import React, { useState, useEffect, useContext } from 'react';

const SHOPIFY_CHECKOUT_STORAGE_KEY = 'shopify_checkout_id';

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STORE_FRONT_ACCESS_TOKEN,
  domain: process.env.GATSBY_SHOPIFY_STORE_URL,
});

const INITIAL_STORE_STATE = {
  client,
  isAdding: false,
  checkout: { lineItems: [] },
};

const StoreContext = React.createContext({
  store: INITIAL_STORE_STATE,
  setStore: () => null,
});

export const createNewCheckout = (store) => {
  return store.checkout.create();
};

export const fetchCheckout = (store, id) => {
  return store.checkout.fetch(id);
};

export const setCheckoutInState = (checkout, setStore) => {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, checkout.id);
  }

  setStore((prevState) => {
    return { ...prevState, checkout };
  });
};

export const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(INITIAL_STORE_STATE);

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined';
      const existingCheckoutId = isBrowser ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY) : null;

      if (existingCheckoutId) {
        try {
          const checkout = await fetchCheckout(client, existingCheckoutId);

          // Make sure this cart hasn't already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout, setStore);
            return;
          }
        } catch (error) {
          console.log('Error when fetching checkout: ', error);
          localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, null);
        }
      }

      // If no existing checkout then create new one.
      const newCheckout = await createNewCheckout(client);
      setCheckoutInState(newCheckout, setStore);
    };

    initializeCheckout();
  }, []);

  return <StoreContext.Provider value={{ store, setStore }}>{children}</StoreContext.Provider>;
};

export const useCartItems = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  if (!checkout.id) {
    return [];
  }

  return checkout.lineItems;
};

export const useCheckout = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  return checkout;
};

export const useAddItemToCart = () => {
  const {
    store: { checkout, client },
    setStore,
  } = useContext(StoreContext);

  async function addItemToCart(addToCartParams) {
    const { quantity, productId, title, customAttributes } = addToCartParams;

    setStore((prevState) => {
      return { ...prevState, isAdding: true };
    });

    const checkoutId = checkout.id;

    const lineItemsToAdd = [
      {
        variantId: productId,
        quantity: parseInt(quantity, 10),
        customAttributes: [
          { key: 'Quantity', value: quantity.toString() },
          { key: 'Title', value: title },
        ],
      },
    ];

    try {
      const newCheckout = await client.checkout.addLineItems(checkoutId, lineItemsToAdd);
      newCheckout?.lineItems?.map((item) => {
        if (item?.variableValues?.checkoutId === checkoutId) {
          item?.customAttributes?.push({
            key: 'customAttr',
            value: isEmpty(customAttributes) ? JSON.stringify({}) : JSON.stringify({ ...customAttributes }),
          });
        }
      });

      setStore((prevState) => {
        return {
          ...prevState,
          checkout: newCheckout,
          isAdding: false,
        };
      });

      return true;
    } catch (error) {
      console.error('Failed to add item to cart. The error is', error);

      return false;
    }
  }

  return addItemToCart;
};

export const useCartCount = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const count = checkout.lineItems.reduce((acc, item) => item.quantity + acc, 0);

  return count;
};

export const useCartTotals = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);

  const total = checkout.totalPriceV2 ? `${Number(checkout.totalPriceV2.amount).toFixed(2)}` : 0;

  return total;
};

export const useRemoveItemFromCart = () => {
  const {
    store: { client, checkout },
    setStore,
  } = useContext(StoreContext);

  async function removeItemFromCart(itemId) {
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [itemId]);

    setStore((prevState) => {
      return { ...prevState, checkout: newCheckout };
    });
  }

  return removeItemFromCart;
};

export const useUpdateItemQuantity = () => {
  const {
    store: { client, checkout },
    setStore,
  } = useContext(StoreContext);

  async function updateQuantityByLineItemId(itemId, quantity) {
    const newCheckout = await client.checkout.updateLineItems(checkout.id, [
      {
        id: itemId,
        quantity: parseInt(quantity, 10),
        customAttributes: [{ key: 'Quantity', value: quantity.toString() }],
      },
    ]);

    //TODO for fixing issue in on increament of quantity

    // newCheckout?.lineItems?.map((item) => {
    //   if (item?.id === itemId) {
    //     item?.customAttributes?.push(
    //       checkout.lineItems
    //         .find((lineItem) => lineItem?.id === itemId)
    //         .customAttributes.find((customAttribute) => customAttribute.key === 'customAttr')
    //     );
    //   }
    // });

    setStore((prevState) => {
      return { ...prevState, checkout: newCheckout };
    });
  }

  return updateQuantityByLineItemId;
};
